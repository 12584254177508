import { type ReactElement } from "react";

export function Dog({
  size = 24,
  strokeWidth = 1.5,
  className = "",
}): ReactElement {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M16.4665 4.65001c0 1.6 0.4 2.89999 -1.6 2.89999 -2.1 0 -2.5 -0.80002 -1.6 -3.30002 0.5 -1.5 1 -3.299983 4.1 -3.299983 2.5 0 3.3 2.500003 3.3 2.500003l2.5 0.79998s0.9 4.60001 -3.3 4.10001c-0.8 3.30001 0 5.80001 -2.5 7.40001l0 7.2h1.6"
        strokeWidth={strokeWidth}
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M17.2557 22.95h-3.3l-0.8 -7.1"
        strokeWidth={strokeWidth}
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M6.2449 15.9381c-0.80221 -0.0773 -3.80496 -0.656 -3.80496 -4.9881 -2.869638 2.9953 -2.046767 8.7115 2.63833 8.0614"
        strokeWidth={strokeWidth}
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M12.9557 6.95s0 3.9 -3.10004 5.8c-3.6 2.1 -4.8 4.7 -4.8 6.7 0 2.3 1.3 3.6 2.9 3.6l3.00004 0"
        strokeWidth={strokeWidth}
      />
    </svg>
  );
}
