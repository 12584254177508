import { type ReactElement } from "react";

export function Horse({
  size = 24,
  strokeWidth = 1.5,
  className = "",
}): ReactElement {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M19.824 16.505v-2.023c0 -2.024 -1.012 -5.058 -4.553 -4.047s-4.552 0 -4.552 0L8.7 3.6H6.166L1.482 5.983a1.012 1.012 0 0 0 -0.521 1.3l0.263 0.657a1.011 1.011 0 0 0 1.35 0.548l1.335 -0.593a1.766 1.766 0 0 0 1.246 0.514 1.747 1.747 0 0 0 1.011 -0.321v5.42a3 3 0 0 0 3 3h7.876"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="m6.56 14.981 -3.934 2.536 0 3.034"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="m8.19 16.505 -0.983 4.046 0.753 2.53"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="M18.559 10.435h0.4a3.037 3.037 0 0 1 2.853 2L23.111 16"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="m16.789 15.493 1.517 4.047 1.518 0 -0.506 3.541"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="M8.696 3.604 8.021 0.919"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
    </svg>
  );
}
