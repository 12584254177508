import { type ReactElement } from "react";

export function Rabbit({
  size = 24,
  strokeWidth = 1.5,
  className = "",
}): ReactElement {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7.51172 12.036c0.69627 -0.0353 1.39194 0.0805 2.03924 0.3394 0.64734 0.2589 1.23094 0.6548 1.71074 1.1606 1.4293 1.9422 2.4675 4.1437 3.057 6.482"
        strokeWidth={strokeWidth}
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M18.7342 23.25h-5.8826c-0.2507 0.0002 -0.4973 -0.0624 -0.7175 -0.1823 -0.2201 -0.1198 -0.4067 -0.293 -0.5425 -0.5036 -1.0113 -1.6024 -2.42199 -2.9143 -4.09347 -3.8069 -2.99522 -1.4976 -5.99044 -2.9953 -5.99044 -6.7393 0 -5.24162 3.74403 -5.99042 5.99044 -5.99042 3.74407 0 7.48807 3.74403 9.73447 4.49282 -1.4976 -2.2464 -4.5687 -9.2702 -2.2464 -9.734457C18.7302 0.0370377 20.9766 11.2691 20.9766 11.2691c0.7198 0.3097 1.324 0.8378 1.7271 1.5097s0.5848 1.4535 0.5193 2.2343c0 2.2465 -1.4976 2.2465 -3.744 2.2465 -2.2464 0 -2.9952 0.7488 -2.9952 0.7488"
        strokeWidth={strokeWidth}
      />
      <path
        stroke="currentColor"
        d="M19.8867 14.2859c-0.2071 0 -0.375 -0.1679 -0.375 -0.375s0.1679 -0.375 0.375 -0.375"
        strokeWidth={strokeWidth}
      />
      <path
        stroke="currentColor"
        d="M19.8867 14.2859c0.2071 0 0.375 -0.1679 0.375 -0.375s-0.1679 -0.375 -0.375 -0.375"
        strokeWidth={strokeWidth}
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M2.51173 8.22998c-0.42497 -0.0973 -0.81252 -0.31598 -1.11553 -0.62943 -0.30301 -0.31346 -0.508427 -0.70819 -0.591279 -1.13621 -0.082852 -0.42803 -0.03957 -0.8709 0.124583 -1.27478 0.164156 -0.40389 0.442106 -0.75138 0.800076 -1.00024 0.35796 -0.24886 0.78051 -0.38836 1.21628 -0.40155 0.43577 -0.01318 0.86598 0.10051 1.23834 0.32727 0.37236 0.22676 0.67081 0.55681 0.85909 0.95003 0.18827 0.39322 0.25826 0.83266 0.20144 1.26491"
        strokeWidth={strokeWidth}
      />
    </svg>
  );
}
