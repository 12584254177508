import { type ReactElement } from "react";

export function SearchReset({
  size = 24,
  strokeWidth = 1.5,
  className = "",
}): ReactElement {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M21.5 12.0067C21.4961 14.8603 20.1281 17.6683 17.5936 19.4128C13.5033 22.2266 7.9029 21.1982 5.08327 17.0991C2.26947 13.0088 3.30664 7.40359 7.39696 4.58979C11.4279 1.81686 16.9154 2.7723 19.7808 6.70306"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.7655 3.27539L19.966 6.68269L16.5781 6.88215"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.2283 8.68652C13.8658 8.68652 15.1929 10.0136 15.1929 11.6511C15.1929 13.2886 13.8658 14.6167 12.2283 14.6167C10.5908 14.6167 9.26367 13.2886 9.26367 11.6511C9.26367 10.0136 10.5908 8.68652 12.2283 8.68652Z"
          stroke="currentColor"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.2461 13.8232L15.7386 15.3128"
          stroke="currentColor"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
