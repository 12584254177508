import { type ReactElement } from "react";

export function Poultry({
  size = 24,
  strokeWidth = 1.5,
  className = "",
}): ReactElement {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M0.75 8.13c0.58991 -0.40271 1.28578 -0.62191 2 -0.63 1.12595 0.06958 2.17862 0.58189 2.92808 1.42504C6.42755 9.76819 6.81291 10.8737 6.75 12v1.5c0 1.7902 0.71116 3.5071 1.97703 4.773C9.9929 19.5388 11.7098 20.25 13.5 20.25s3.5071 -0.7112 4.773 -1.977c1.2658 -1.2659 1.977 -2.9828 1.977 -4.773v-6c0 -0.49246 -0.097 -0.98009 -0.2855 -1.43506 -0.1884 -0.45497 -0.4646 -0.86837 -0.8128 -1.21659 -0.3483 -0.34822 -0.7617 -0.62444 -1.2166 -0.8129C17.4801 3.847 16.9925 3.75 16.5 3.75c-2.07 0 -3 1.68 -3 3.75v2.25c-0.0258 0.3893 -0.1921 0.7561 -0.468 1.032 -0.2759 0.2759 -0.6427 0.4422 -1.032 0.468H9.75s-0.75 3.74 3 3.74"
        strokeWidth={strokeWidth}
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M0.75 11.99c0.70581 -0.8602 1.71255 -1.4195 2.81579 -1.5644 1.10323 -0.1449 2.22025 0.1356 3.12421 0.7844"
        strokeWidth={strokeWidth}
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M0.75 15.75S3 11.99 6.79 14.28"
        strokeWidth={strokeWidth}
      />
      <path
        stroke="currentColor"
        d="M16.87 7.505c-0.2071 0 -0.375 -0.16789 -0.375 -0.375 0 -0.2071 0.1679 -0.375 0.375 -0.375"
        strokeWidth={strokeWidth}
      />
      <path
        stroke="currentColor"
        d="M16.87 7.505c0.2071 0 0.375 -0.16789 0.375 -0.375 0 -0.2071 -0.1679 -0.375 -0.375 -0.375"
        strokeWidth={strokeWidth}
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M20.1801 6.75c1.52 0 3.07 1.18 3.07 2.4 -0.75 0.6 -3 0.6 -3 0.6"
        strokeWidth={strokeWidth}
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M14.53 4.5h-0.28s-0.75 0 -0.75 -1.36S15 0.75 16.87 0.75s3.38 1.07 3.38 2.39c0 0.72 -0.74 1.36 -1.11 1.69"
        strokeWidth={strokeWidth}
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.5 20.25v3"
        strokeWidth={strokeWidth}
      />
    </svg>
  );
}
