import { type ReactElement } from "react";

export function ArrowLeft({
  size = 24,
  strokeWidth = 1.5,
  className = "",
}): ReactElement {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={className}
    >
      <title>Iconly/Two-tone/Arrow - Left</title>
      <g
        id="Iconly/Two-tone/Arrow---Left"
        stroke="none"
        strokeWidth={strokeWidth}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="Arrow---Left"
          transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000) translate(5.500000, 4.000000)"
          stroke="currentColor"
          strokeWidth={strokeWidth}
        >
          <line
            x1="6.7743"
            y1="15.75"
            x2="6.7743"
            y2="0.75"
            id="Stroke-1"
            opacity="0.400000006"
          />
          <polyline
            id="Stroke-3"
            points="12.7987 9.7002 6.7747 15.7502 0.7497 9.7002"
          />
        </g>
      </g>
    </svg>
  );
}
