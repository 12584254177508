import { type ReactElement } from "react";

export function Parrot({
  size = 24,
  strokeWidth = 1.5,
  className = "",
}): ReactElement {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.514 12.75C12.014 22.5 0.764033 23.25 0.764033 23.25c0 -3.55 -0.877 -13.375 11.234967 -15.74 -0.4693 -0.66096 -0.7257 -1.44941 -0.735 -2.26 0 -2.485 2.35 -4.5 5.25 -4.5s5.25 1.265 5.25 3.75c0 1.5 -0.75 1.5 -0.75 1.5"
        strokeWidth={strokeWidth}
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M19.514 9c0.5209 0.44464 1.1832 0.68892 1.868 0.68892 0.6848 0 1.3472 -0.24428 1.868 -0.68892 -0.0304 -0.6662 -0.2596 -1.30803 -0.6581 -1.84274 -0.3986 -0.53471 -0.9481 -0.93779 -1.5779 -1.15726 0 0 -2.25 0 -1.5 3Z"
        strokeWidth={strokeWidth}
      />
      <path
        stroke="currentColor"
        d="M16.8892 5.25c-0.2071 0 -0.375 -0.16789 -0.375 -0.375s0.1679 -0.375 0.375 -0.375"
        strokeWidth={strokeWidth}
      />
      <path
        stroke="currentColor"
        d="M16.8892 5.25c0.2071 0 0.375 -0.16789 0.375 -0.375s-0.1679 -0.375 -0.375 -0.375"
        strokeWidth={strokeWidth}
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M19.514 9c0.2691 1.631 0.1365 3.303 -0.3863 4.8712 -0.5227 1.5683 -1.4198 2.9854 -2.6137 4.1288 -1.4383 1.5387 -3.2037 2.7347 -5.166 3.5"
        strokeWidth={strokeWidth}
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m16.4071 18.1071 0.857 5.143"
        strokeWidth={strokeWidth}
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M14.264 19.932v3.318"
        strokeWidth={strokeWidth}
      />
    </svg>
  );
}
