import { type ReactElement } from "react";

export function Other({
  size = 24,
  strokeWidth = 1.5,
  className = "",
}): ReactElement {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="m10.8261 12.1 -2.05432 2.6c-0.68478 0.9 -0.09782 2.3 1.07609 2.3h4.10873c1.1739 0 1.8587 -1.4 1.076 -2.3l-2.0543 -2.6c-0.4891 -0.8 -1.5652 -0.8 -2.1522 0Z"
        strokeWidth={strokeWidth}
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M18.3587 21.5V18c2.7391 0 4.8913 -2.2 4.8913 -5 0 -2.1 -1.2717 -3.90001 -3.0326 -4.60001 0.0978 -0.3 0.0978 -0.59999 0.0978 -0.89999 0 -2.8 -2.1522 -5 -4.8913 -5 -1.3696 0 -2.5435 0.49999 -3.4239 1.39999C11.1196 2.99999 9.94565 2.5 8.57609 2.5c-2.73913 0 -4.89131 2.2 -4.89131 5 0 0.3 0.00001 0.59999 0.09783 0.89999C2.02175 9.19999 0.75 10.9 0.75 13c0 2.8 2.15217 5 4.8913 5v3.5"
        strokeWidth={strokeWidth}
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M5.15214 14.5c0.81042 0 1.4674 -0.6716 1.4674 -1.5s-0.65698 -1.5 -1.4674 -1.5c-0.81041 0 -1.46739 0.6716 -1.46739 1.5s0.65698 1.5 1.46739 1.5Z"
        strokeWidth={strokeWidth}
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M8.57609 9c0.81042 0 1.46741 -0.67157 1.46741 -1.5S9.38651 6 8.57609 6c-0.81041 0 -1.46739 0.67157 -1.46739 1.5S7.76568 9 8.57609 9Z"
        strokeWidth={strokeWidth}
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M15.4239 9c0.8105 0 1.4674 -0.67157 1.4674 -1.5S16.2344 6 15.4239 6c-0.8104 0 -1.4674 0.67157 -1.4674 1.5s0.657 1.5 1.4674 1.5Z"
        strokeWidth={strokeWidth}
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M18.8478 14.5c0.8104 0 1.4674 -0.6716 1.4674 -1.5s-0.657 -1.5 -1.4674 -1.5 -1.4674 0.6716 -1.4674 1.5 0.657 1.5 1.4674 1.5Z"
        strokeWidth={strokeWidth}
      />
    </svg>
  );
}
