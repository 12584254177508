import { type ReactElement } from "react";

export function Cat({
  size = 24,
  strokeWidth = 1.5,
  className = "",
}): ReactElement {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M11 8.588A3.751 3.751 0 0 1 8.96 5.25V0.75l3 1.5h1.5l3 -1.5v4.5a3.752 3.752 0 0 1 -2.268 3.45"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="M9.921 7.757 7.46 13.5a4.975 4.975 0 0 0 -3.75 3.75 8.354 8.354 0 0 0 -0.01 3.65 2.975 2.975 0 0 0 2.928 2.35H18.71c2.25 0 2.25 -3.75 0 -3.75"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="m14.96 8.25 0 12"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="m11.96 20.25 0 -6"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
    </svg>
  );
}
