import { type ReactElement } from "react";

export function Sheep({
  size = 24,
  strokeWidth = 1.5,
  className = "",
}): ReactElement {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M14.76 5a3 3.75 0 1 0 6 0 3 3.75 0 1 0 -6 0Z"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="M15.01 3.5a3.448 3.448 0 0 1 -2.5 1.5"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="M20.51 3.5a3.448 3.448 0 0 0 2.5 1.5"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="M18.921 8.459c0.844 0.611 1.839 2.142 1.839 5.166a6.2 6.2 0 0 1 -6 6.375 5.735 5.735 0 0 1 -3.75 -1.435A5.735 5.735 0 0 1 7.26 20a6.2 6.2 0 0 1 -6 -6.375 6.2 6.2 0 0 1 6 -6.375 5.735 5.735 0 0 1 3.75 1.435"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="m17.01 19.537 0 3.463"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="m13.26 19.791 0 3.209"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="m4.26 19.147 0 3.853"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
    </svg>
  );
}
