import { type ReactElement } from "react";

export function External({
  size = 24,
  strokeWidth = 1.5,
  className = "",
}): ReactElement {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M10.2311 5H7.25067C4.63822 5 3 6.84978 3 9.46756V16.5324C3 19.1502 4.63022 21 7.25067 21H14.7476C17.3689 21 19 19.1502 19 16.5324V13.9849"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 8.13166V3M21 3H15.8684M21 3L13 11"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
