import { type ReactElement } from "react";

export function Search({
  size = 24,
  strokeWidth = 1.5,
  className = "",
}): ReactElement {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g
        id="Iconly/Light/Search"
        stroke="none"
        strokeWidth={strokeWidth}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="Search"
          transform="translate(2.000000, 2.000000)"
          stroke="currentColor"
          strokeWidth={strokeWidth}
        >
          <circle
            id="Ellipse_739"
            cx="9.76659044"
            cy="9.76659044"
            r="8.9885584"
          />
          <line
            x1="16.0183067"
            y1="16.4851259"
            x2="19.5423342"
            y2="20.0000001"
            id="Line_181"
          />
        </g>
      </g>
    </svg>
  );
}
