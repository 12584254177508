import { type ReactElement } from "react";

export function Pig({
  size = 24,
  strokeWidth = 1.5,
  className = "",
}): ReactElement {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          transform="translate(1.5, 2.5)"
          stroke="currentColor"
          strokeWidth={strokeWidth}
        >
          <path
            d="M21.406,13.558 C21.4629,13.1242 22.4942,11.6875 22.5,11.25 C22.5,11.25 20.25,10.5 20.25,9 C20.25,6.1 17.899,3 15,3 L15,0 C15,0 11.25,3 9.75,3 L5.25,3 C2.35,3 0,6.1 0,9 C0,11.9 1.5,14.25 3,15 C4.048,15.524 4.364,17.146 4.459,18.076 C4.47759,18.2607 4.56409,18.4319 4.70174,18.5564 C4.83939,18.681 5.01838,18.750052 5.204,18.750052 L6.164,18.750052 C6.33136,18.7503 6.49399,18.6945 6.62594,18.5915 C6.75789,18.4886 6.85156,18.3444 6.892,18.182 L7.5,15.75 L12,15.75 L12.608,18.182 C12.6484,18.3444 12.7421,18.4886 12.8741,18.5915 C13.006,18.6945 13.1686,18.7503 13.336,18.750052 L14.414,18.750052 C14.5814,18.7503 14.744,18.6945 14.8759,18.5915 C15.0079,18.4886 15.1016,18.3444 15.142,18.182 C15.339,17.2868925 15.5666667,16.3755592 15.825,15.448 C17.4943195,14.1555643 19.3546529,13.5255643 21.406,13.558 Z"
            id="Path"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.125,8.25 C15.9179,8.25 15.75,8.0821 15.75,7.875 C15.75,7.66789 15.9179,7.5 16.125,7.5"
            id="Path"
          />
          <path
            d="M16.125,8.25 C16.3321,8.25 16.5,8.0821 16.5,7.875 C16.5,7.66789 16.3321,7.5 16.125,7.5"
            id="Path"
          />
          <path
            d="M1.01004,5.64 L0.75004,5.25 C0.32696,4.57579 0.102539,3.79596 0.102539,3 C0.102539,2.20404 0.32696,1.42421 0.75004,0.75"
            id="Path"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  );
}
