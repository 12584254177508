import { type ReactElement } from "react";

export function Facebook({
  size = 24,
  strokeWidth = 1.5,
  className = "",
}): ReactElement {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        opacity="0.4"
        d="M10.4579 21H7.78216C4.83405 21 3 18.9188 3 15.9736V8.02638C3 5.08119 4.84281 3 7.78216 3H16.2169C19.165 3 21 5.08119 21 8.02638V15.9736C21 18.9188 19.165 21 16.2159 21H13.5798"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4576 21.0005V16.1898H8.5498C8.31034 16.1898 8.11621 15.9957 8.11621 15.7563V13.5016C8.11621 13.2621 8.31034 13.068 8.54981 13.068H10.4576V10.7266C10.4576 9.68875 10.8685 8.69734 11.6009 7.96328C12.3324 7.23766 13.3247 6.82422 14.36 6.82422H16.2678C16.5072 6.82422 16.7014 7.01835 16.7014 7.25781V9.5125C16.7014 9.75197 16.5072 9.94609 16.2678 9.94609H14.36C14.1532 9.94609 13.9541 10.0322 13.8081 10.1722C13.6613 10.3207 13.5795 10.5156 13.5795 10.7266V13.068H16.2678C16.5072 13.068 16.7014 13.2621 16.7014 13.5016V15.7563C16.7014 15.9957 16.5072 16.1898 16.2678 16.1898H13.5795V21.0005"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
